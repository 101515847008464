import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: 'overview/emergency' },

    // Redirect signed-in user to the '/dashboards/project'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'overview/emergency',
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.routes'
                    ),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.routes'
                    ),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.routes'
                    ),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.routes'),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            // overview

            {
                path: 'overview',
                children: [
                    {
                        path: 'emergency',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/overview/emergency/emergency.routes'
                            ),
                    },
                    {
                        path: 'health',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/overview/health/health.routes'
                            ),
                    },
                ],
            },

            {
                path: 'personal-settings',
                children: [
                    {
                        path: 'personal',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/personal-settings/personal/personal.routes'
                            ),
                    },
                    {
                        path: 'health',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/personal-settings/health/health.routes'
                            ),
                    },
                    {
                        path: 'contacts',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/personal-settings/contacts/contacts.routes'
                            ),
                    },
                    {
                        path: 'my-caregiver',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/personal-settings/contacts/contacts.routes'
                            ),
                    },
                    {
                        path: 'device-management',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/personal-settings/device-management/device-management.routes'
                            ),
                    },
                    {
                        path: 'settings',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/settings/settings.routes'
                            ),
                    },
                ],
            },

            {
                path: 'service-settings',
                children: [
                    {
                        path: 'emergency-call',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/service-settings/emergency-call/emergency-call.routes'
                            ),
                    },
                    {
                        path: 'automatic-emergency',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/service-settings/automatic-emergency/automatic-emergency.routes'
                            ),
                    },
                    {
                        path: 'local-orientation',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/service-settings/local-orientation/local-orientation.route'
                            ),
                    },
                    {
                        path: 'drinking-plan',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/service-settings/drinking-plan/drinking-plan.routes'
                            ),
                    },
                    {
                        path: 'exercise-plan',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/service-settings/exercise-plan/exercise-plan.route'
                            ),
                    },
                    {
                        path: 'general-reminder',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/service-settings/general-reminder/general-reminder.routes'
                            ),
                    },
                    {
                        path: 'questionnaire',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/service-settings/questionnaire/questionnaire.routes'
                            ),
                    },
                    {
                        path: 'routine-plan',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/service-settings/routine-plan/routine-plan.routes'
                            ),
                    },
                ],
            },

            {
                path: 'worth-knowing',
                children: [
                    {
                        path: 'AP_AP',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/worth-knowing/ap/ap.routes'
                            ),
                        data: { key: 'AP_AP' },
                    },
                    {
                        path: 'AP_CE',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/worth-knowing/ap/ap.routes'
                            ),
                        data: { key: 'AP_CE' },
                    },
                    {
                        path: 'AP_Manual',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/worth-knowing/ap/ap.routes'
                            ),
                        data: { key: 'AP_Manual' },
                    },
                    {
                        path: 'AP_compatibility',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/worth-knowing/ap/ap.routes'
                            ),
                        data: { key: 'AP_compatibility' },
                    },
                    {
                        path: 'AP_DS_web',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/worth-knowing/ap/ap.routes'
                            ),
                        data: { key: 'AP_DS_web' },
                    },
                    {
                        path: 'AP_NB',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/worth-knowing/ap/ap.routes'
                            ),
                        data: { key: 'AP_NB' },
                    },
                    {
                        path: 'AP_Impressum',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/worth-knowing/ap/ap.routes'
                            ),
                        data: { key: 'AP_Impressum' },
                    },
                    {
                        path: 'AP_FAQ',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/worth-knowing/ap/ap.routes'
                            ),
                        data: { key: 'AP_FAQ' },
                    },
                    // {
                    //     path: 'emergency-call',
                    //     loadChildren: () =>
                    //         import(
                    //             'app/modules/admin/service-settings/emergency-call/emergency-call-routes'
                    //         ),
                    // }
                ],
            },

            {
                path: 'admin',
                children: [
                    {
                        path: 'watch-table',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/watch-table/watch-table.routes'
                            ),
                    },
                    {
                        path: 'watch-inflow',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/watch-inflow/watch-inflow.routes'
                            ),
                    },
                    /*{
                        path: 'esim-knox',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/esim-knox/esim-knox.routes'
                                ),
                    },
                    {
                        path: 'shipping',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/shipping/shipping.routes'
                                ),
                    },*/
                    {
                        path: 'device-insights',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/device-insights/device-insights.routes'
                            ),
                    },
                    {
                        path: 'logs',
                        loadChildren: () =>
                            import('app/modules/admin/logs/logs.routes'),
                    },
                ],
            },

            // Dashboards
            {
                path: 'dashboards',
                children: [
                    {
                        path: 'project',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/dashboards/project/project.routes'
                            ),
                    },
                    {
                        path: 'analytics',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/dashboards/analytics/analytics.routes'
                            ),
                    },
                    {
                        path: 'finance',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/dashboards/finance/finance.routes'
                            ),
                    },
                    {
                        path: 'crypto',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/dashboards/crypto/crypto.routes'
                            ),
                    },
                ],
            },

            // Apps
            {
                path: 'apps',
                children: [
                    {
                        path: 'academy',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/apps/academy/academy.routes'
                            ),
                    },
                    {
                        path: 'chat',
                        loadChildren: () =>
                            import('app/modules/admin/apps/chat/chat.routes'),
                    },
                    {
                        path: 'contacts',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/apps/contacts/contacts.routes'
                            ),
                    },
                    {
                        path: 'ecommerce',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/apps/ecommerce/ecommerce.routes'
                            ),
                    },
                    {
                        path: 'file-manager',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/apps/file-manager/file-manager.routes'
                            ),
                    },
                    {
                        path: 'help-center',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/apps/help-center/help-center.routes'
                            ),
                    },
                    {
                        path: 'mailbox',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/apps/mailbox/mailbox.routes'
                            ),
                    },
                    {
                        path: 'notes',
                        loadChildren: () =>
                            import('app/modules/admin/apps/notes/notes.routes'),
                    },
                    {
                        path: 'scrumboard',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/apps/scrumboard/scrumboard.routes'
                            ),
                    },
                    {
                        path: 'tasks',
                        loadChildren: () =>
                            import('app/modules/admin/apps/tasks/tasks.routes'),
                    },
                ],
            },

            // Pages
            {
                path: 'pages',
                children: [
                    // Activities
                    {
                        path: 'activities',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/pages/activities/activities.routes'
                            ),
                    },

                    // Authentication
                    {
                        path: 'authentication',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/pages/authentication/authentication.routes'
                            ),
                    },

                    // Coming Soon
                    {
                        path: 'coming-soon',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/pages/coming-soon/coming-soon.routes'
                            ),
                    },

                    // Error
                    {
                        path: 'error',
                        children: [
                            {
                                path: '404',
                                loadChildren: () =>
                                    import(
                                        'app/modules/admin/pages/error/error-404/error-404.routes'
                                    ),
                            },
                            {
                                path: '500',
                                loadChildren: () =>
                                    import(
                                        'app/modules/admin/pages/error/error-500/error-500.routes'
                                    ),
                            },
                        ],
                    },

                    // Invoice
                    {
                        path: 'invoice',
                        children: [
                            {
                                path: 'printable',
                                children: [
                                    {
                                        path: 'compact',
                                        loadChildren: () =>
                                            import(
                                                'app/modules/admin/pages/invoice/printable/compact/compact.routes'
                                            ),
                                    },
                                    {
                                        path: 'modern',
                                        loadChildren: () =>
                                            import(
                                                'app/modules/admin/pages/invoice/printable/modern/modern.routes'
                                            ),
                                    },
                                ],
                            },
                        ],
                    },

                    // Maintenance
                    {
                        path: 'maintenance',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/pages/maintenance/maintenance.routes'
                            ),
                    },

                    // Pricing
                    {
                        path: 'pricing',
                        children: [
                            {
                                path: 'modern',
                                loadChildren: () =>
                                    import(
                                        'app/modules/admin/pages/pricing/modern/modern.routes'
                                    ),
                            },
                            {
                                path: 'simple',
                                loadChildren: () =>
                                    import(
                                        'app/modules/admin/pages/pricing/simple/simple.routes'
                                    ),
                            },
                            {
                                path: 'single',
                                loadChildren: () =>
                                    import(
                                        'app/modules/admin/pages/pricing/single/single.routes'
                                    ),
                            },
                            {
                                path: 'table',
                                loadChildren: () =>
                                    import(
                                        'app/modules/admin/pages/pricing/table/table.routes'
                                    ),
                            },
                        ],
                    },

                    // Profile
                    {
                        path: 'profile',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/pages/profile/profile.routes'
                            ),
                    },

                    // Settings
                    {
                        path: 'settings',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/pages/settings/settings.routes'
                            ),
                    },
                ],
            },

            // User Interface
            {
                path: 'ui',
                children: [
                    // Fuse Components
                    {
                        path: 'fuse-components',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ui/fuse-components/fuse-components.routes'
                            ),
                    },

                    // Other Components
                    {
                        path: 'other-components',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ui/other-components/other-components.routes'
                            ),
                    },

                    // Advanced Search
                    {
                        path: 'advanced-search',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ui/advanced-search/advanced-search.routes'
                            ),
                    },

                    // Animations
                    {
                        path: 'animations',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ui/animations/animations.routes'
                            ),
                    },

                    // Page Layouts
                    {
                        path: 'page-layouts',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ui/page-layouts/page-layouts.routes'
                            ),
                    },
                ],
            },

            // Documentation
            {
                path: 'docs',
                children: [
                    // Changelog
                    {
                        path: 'changelog',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/docs/changelog/changelog.routes'
                            ),
                    },

                    // Guides
                    {
                        path: 'guides',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/docs/guides/guides.routes'
                            ),
                    },
                ],
            },

            // 404 & Catch all
            {
                path: '404-not-found',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        'app/modules/admin/pages/error/error-404/error-404.routes'
                    ),
            },
            { path: '**', redirectTo: '404-not-found' },
        ],
    },
];
