import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';
import {
    AddressDTO,
    CohabitingSituationDTO,
    EMERGENCY_CALL_WITHOUT_VOICE,
    GENDER,
    HealthInsuranceDTO,
    MARITAL_STATUS,
    MedicalUserDataDTO,
    ResidienceDTO,
} from 'app/modules/shared/models/user';
import { environment } from 'environments/environment';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    private readonly baseUrl = environment.baseUrl;

    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _activeUser: ReplaySubject<User> = new ReplaySubject<User>(null);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    /**
     * Setter & getter for active user
     *
     * @param value
     */
    set activeUser(value: User) {
        // Store the value
        this._activeUser.next(value);
    }

    get activeUser$(): Observable<User> {
        return this._activeUser.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    getUserBase(): Observable<User> {
        return this._httpClient
            .get<User>(this.baseUrl + '/api/user/me/base-data')
            .pipe(
                tap((user) => {
                    user = this.mapUserData(user);
                    return user;
                })
            );
        // .pipe(
        //     tap((user) => {
        //         user.active = true;
        //         this._user.next(user);
        //     })
        // );
    }

    getUser(userId: number): Observable<User> {
        return this._httpClient
            .get<User>(`${this.baseUrl}/api/user/${userId}/base-data`)
            .pipe(
                tap((user) => {
                    user = this.mapUserData(user);
                    return user;
                })
            );
    }

    getActiveUser(): Observable<User> {
        return this.activeUser$.pipe(
            switchMap((user) =>
                this._httpClient
                    .get<User>(
                        `${this.baseUrl}/api/user/${user.userId}/base-data`
                    )
                    .pipe(
                        tap((activeUser) => {
                            activeUser = this.mapUserData(activeUser);
                            // this._activeUser.next(person);
                            return activeUser;
                        })
                    )
            )
        );
    }

    sendFCMToken(userId: number, fcm: string): Observable<boolean> {
        return this._httpClient.put<any>(
            `${this.baseUrl}/api/user/${userId}/firebase/token`,
            fcm
        );
    }

    /**
     * Get the current signed-in user data
     */
    // get(): Observable<User> {
    //     return this._httpClient.get<User>('api/common/user').pipe(
    //         tap((user) => {
    //             this._user.next(user);
    //         })
    //     );
    // }

    /**
     * Update the user
     *
     * @param user
     */
    // update(user: User): Observable<any> {
    //     return this._httpClient.patch<User>('api/common/user', { user }).pipe(
    //         map((user) => {
    //             this._user.next(user);
    //         })
    //     );
    // }

    /**
     * Save User
     *
     * @param id
     * @param user
     */
    saveUser(user: User): Observable<User> {
        return this._httpClient
            .post(`${this.baseUrl}/api/user/${user.userId}/base-data/v2`, user)
            .pipe(
                take(1),
                tap((_user: User) => {
                    // Update the person if it's selected
                    // this._user.next(_user);
                    // Return the updated person
                    return _user;
                })
            );
    }

    /**
     * delete the user
     *
     * @param user
     */
    delete(userId: number): Observable<any> {
        return this._httpClient.delete('api/user/' + userId);
    }

    mapUserData(_person: User) {
        let person = new User();
        person = _person;
        person.salutation = _person.salutation || '-';
        // data["ADD_PROFILE_PIC"] = ""
        person.sex =
            !_person.sex || _person.sex === GENDER.UNKNOWN
                ? GENDER.UNKNOWN
                : _person.sex;
        person.birthday = _person.birthday;

        person.maritalStatus =
            !_person.maritalStatus ||
            _person.maritalStatus === MARITAL_STATUS.UNKNOWN
                ? MARITAL_STATUS.UNKNOWN
                : _person.maritalStatus;

        person.firstLanguage = _person.firstLanguage || 'german';
        if (!person.address) {
            person.address = new AddressDTO();
        }
        // data["POSTAL_CODE"] = userData.address?.zip
        // data["STREET_NO"] = userData.address?.streetNumber
        // data["STREET"] =  userData.address?.street
        // data["CITY"] = userData.address?.city
        // data["ADDRESS_SUFFIX"] = userData.address?.addressSuffix
        // data["DISTRICT"] = userData.address?.district
        // data["REGION"] = userData.address?.region
        if (!person.residence) {
            person.residence = new ResidienceDTO();
        }
        // data["COUNTRY"] = userData.address?.country
        // data["TYPE_OF_BUILDING"] = userData.residence?.type
        // data["LOC_OF_APART"] = userData.residence?.access
        // data["SPECIAL_FEATURE_OF_APART"] = userData.residence?.notes
        // data["PLACE_OF_MEDICATION"] = userData.medicalUserData?.medicationLocation

        if (!person.cohabitingSituation) {
            person.cohabitingSituation = new CohabitingSituationDTO();
        }

        // data["KEY_ON_SITE"] = userData.residence?.keySite
        // data["KEY_PIN"] = userData.residence?.keyNumber

        person.actionForEmergencyCallWithoutVoice =
            _person.actionForEmergencyCallWithoutVoice ||
            EMERGENCY_CALL_WITHOUT_VOICE.CALL_AMBULANCE;
        // data["INTEREST_AND_HOBBIES"] = userData.interests
        // data["REMARKS"] = userData.notesSocialEnvironment

        if (!person.medicalUserData) {
            person.medicalUserData = new MedicalUserDataDTO();
        }

        if (!person.medicalUserData?.healthInsurance) {
            person.medicalUserData.healthInsurance = new HealthInsuranceDTO();
        }
        return person;
    }
}
