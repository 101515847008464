export const environment = {
    name: 'development',
    production: false,
    baseUrl: 'https://dev.almaphil.net',
    repoUrl: 'https://repository.dev.almaphil.net',
    docUrl: 'https://almaphil.de/docs/',
    ssoUrl: 'https://sso.dev.almaphil.de',
    sentry: {
        tracePropagationTargets: [
            'localhost',
            'dev.almaphil.de',
            'https://dev.almaphil.net/api',
        ],
    },
};
