import { inject, Pipe, PipeTransform } from '@angular/core';
import { AssetsService } from '../services/assets.service';

@Pipe({
    name: 'assetsSource',
    standalone: true,
})
export class AssetsSourcePipe implements PipeTransform {
    private subDomain: string = window.location.hostname.split('.')[0];

    // This will be the url to our assets server.
    // Ex: https://our-assets.com/assets
    private assetsURL: string;

    private assets = inject(AssetsService).assets;

    constructor() {
        this.subDomain =
            this.subDomain === 'localhost' ? 'adele' : this.subDomain;
        this.assetsURL = window.location.origin + '/assets/images';
    }

    transform(imgName: string): string {
        return `${this.assetsURL}/${this.subDomain}/${
            this.assets[this.subDomain][imgName]
        }`;
    }
}
