// export interface User {
//     id: number;
//     name: string;
//     email: string;
//     avatar?: string;
//     status?: string;
//     active?: boolean;
// }

import { UserDTO } from 'app/modules/shared/models/user';

// export interface IAddress {
//     street: string;
//     streetNumber: string;
//     addressSuffix: string;
//     city: string;
//     zip: string;
//     country: 'EP';
//     region: string;
//     district: string;
// }

// export interface IResidience {
//     type: string;
//     access: string;
//     notes: string;
//     keyNumber: string;
//     keySite: string;
// }

// export interface ICohabitingSituation {
//     withPartner: true;
//     withChildren: true;
//     withOtherRelatives: true;
//     withDogs: true;
//     withCats: true;
//     withDangerousAnimals: true;
//     withHarmlessAnimals: true;
// }

// export interface IHealthInsurance {
//     insurer: string;
//     insuranceNumber: string;
//     insuranceType: 'PUBLIC';
//     kvNumber: string;
// }

// export interface IAnamnesis {
//     medications: [string];
//     diseases: [string];
//     allergies: [string];
//     noAnamnesisInfo: true;
// }

// export interface IMedicalUserData {
//     healthInsurance: IHealthInsurance;
//     careLevel: 'NONE';
//     anamnesis: IAnamnesis;
//     medicationLocation: string;
//     hasAdvanceDirective: true;
//     advanceDirectiveLocation: string;
// }

// export interface IBankAccount {
//     accountHolder: string;
//     bankName: string;
//     iban: string;
//     bic: string;
//     mandateReferenceNumber: string;
// }

// export interface IUser {
//     userId: 0;
//     active?: boolean;
//     status?: string;
//     avatar?: string;
//     login: string;
//     firstName: string;
//     lastName: string;
//     nickName: string;
//     title: string;
//     firstLanguage: string;
//     colloqAdd: true;
//     salutation: string;
//     sex: 'MALE';
//     maritalStatus: 'SINGLE';
//     address: IAddress;
//     residence: IResidience;
//     cohabitingSituation: ICohabitingSituation;
//     medicalUserData: IMedicalUserData;
//     bankAccount: IBankAccount;
//     birthday: '2024-04-23';
//     age: 0;
//     email: string;
//     phoneHome: string;
//     phoneMobile: string;
//     phoneEmergencyNumber: string;
//     waitUntilDisponentTalks: true;
//     isAgent: true;
//     userEmergencyId: string;
//     interests: string;
//     notesMedicalPreconditions: string;
//     notesSocialEnvironment: string;
//     actionForEmergencyCallWithoutVoice: 'CALL_AMBULANCE_AND_CONTACT';
// }

export class User extends UserDTO {
    active?: boolean;
    status?: string;
    avatar?: string;
}
