import { Component } from '@angular/core';
import { AssetsSourcePipe } from '../../pipes/assets.pipe';

@Component({
    selector: 'splash-screen',
    standalone: true,
    imports: [AssetsSourcePipe],
    templateUrl: './splash-screen.component.html',
    styleUrl: './splash-screen.component.scss',
})
export class SplashScreenComponent {}
