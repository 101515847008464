import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { catchError, Observable, of, switchMap, take, throwError } from 'rxjs';
import { User } from '../user/user.types';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

export interface IUserLogin {
    // email: string;
    username: string;
    password: string;
    rememberMe?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    private readonly baseUrl = environment.baseUrl;

    // private readonly JWT_TOKEN = 'JWT_TOKEN';
    // private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
    // private loggedUser!: string | null;

    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);

    private _loginUser = null;
    private _accessToken: string = null;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // 'X-Requested-With': 'XMLHttpRequest',
            // 'Access-Control-Allow-Origin': '*'
        }),
    };

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set token(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get token(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    get authenticated(): boolean {
        return this._authenticated;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    // signIn(credentials: { email: string; password: string }): Observable<any>
    // {
    //     // Throw error, if the user is already logged in
    //     if ( this._authenticated )
    //     {
    //         return throwError('User is already logged in.');
    //     }

    //     return this._httpClient.post('api/auth/sign-in', credentials).pipe(
    //         switchMap((response: any) =>
    //         {
    //             // Store the access token in the local storage
    //             this.accessToken = response.accessToken;

    //             // Set the authenticated flag to true
    //             this._authenticated = true;

    //             // Store the user on the user service
    //             this._userService.user = response.user;

    //             // Return a new observable with the response
    //             return of(response);
    //         }),
    //     );
    // }

    signIn(user: IUserLogin): Observable<boolean> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }
        return this._httpClient
            .post<any>(`${this.baseUrl}/api/authenticate`, user)
            .pipe(
                switchMap((res: any) => {
                    console.log(res);

                    // hide loading
                    if (res?.id_token) {
                        // Store the access token in the local storage
                        this.token = res?.id_token;

                        // // Set the authenticated flag to true
                        // this._authenticated = true;
                        this.fetchUserData();
                    } else {
                        // show error toast
                    }

                    // Return a new observable with the response
                    return of(true);
                }),
                catchError((error) => {
                    console.log(error);
                    // alert(error.error);
                    // return of(false);
                    return throwError(() => error);
                })
            );
    }

    ///Fetch User Data
    private fetchUserData(): Observable<any> {
        let user = this.getStore('user');
        if (user) {
            this._userService.user = JSON.parse(user);
            this._userService.activeUser = JSON.parse(user);
            return of(true);
        }

        return this._userService.getUserBase().pipe(
            switchMap((_user: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                const user: User = _user;
                user.active = true;
                this._userService.user = user;
                this._userService.activeUser = user;
                this.setStore('user', JSON.stringify(user));

                // this.sendFCMToken(user.userId);

                // Return true
                return of(true);
            }),
            catchError(() => {
                // Return false
                return of(false);
            })
        );
    }

    ///Send FCM Token
    private sendFCMToken(userId: number) {
        let fcm = '';
        // show loading
        this._userService.sendFCMToken(userId, fcm).subscribe((success) => {
            // hide loading
            if (success) {
                // navigate to home
            }
        });
    }

    /**
     * Sign in using the access token
     */
    // signInUsingToken(): Observable<any> {
    //     // Sign in using the token
    //     return this._httpClient
    //         .post('api/auth/sign-in-with-token', {
    //             accessToken: this.token,
    //         })
    //         .pipe(
    //             catchError(() =>
    //                 // Return false
    //                 of(false)
    //             ),
    //             switchMap((response: any) => {
    //                 // Replace the access token with the new one if it's available on
    //                 // the response object.
    //                 //
    //                 // This is an added optional step for better security. Once you sign
    //                 // in using the token, you should generate a new one on the server
    //                 // side and attach it to the response object. Then the following
    //                 // piece of code can replace the token with the refreshed one.
    //                 if (response.accessToken) {
    //                     this.token = response.accessToken;
    //                 }

    //                 // Set the authenticated flag to true
    //                 this._authenticated = true;

    //                 // Store the user on the user service
    //                 this._userService.user = response.user;

    //                 // Return true
    //                 return of(true);
    //             })
    //         );
    // }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        // localStorage.removeItem('accessToken');
        this.removeStore('user');
        // Set the authenticated flag to false
        this._authenticated = false;
        this._loginUser = null;
        this.logout();
        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user): Observable<any> {
        console.log(user);
        return this._httpClient.post<any>(
            `${this.baseUrl}/api/user/registration/register`,
            user
        );
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        // if (this._authenticated) {
        //     return of(true);
        // }

        // Check the access token availability
        // if (!this.accessToken) {
        //     return of(false);
        // }
        // if (this.hasIdToken && this.hasValidAccessToken) {
        if (this.authenticated) {
            return this._loginUser ? of(true) : this.fetchUserData();
        }

        // If the access token exists, and it didn't expire, sign in using it
        // return this.signInUsingToken();
        return of(false);
    }

    // OAUTH openID

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private router: Router,
        private _activateRoute: ActivatedRoute
    ) {
        this._userService.user$.subscribe((user) => {
            if (user) {
                this._loginUser = user;
            }
        });

        // Useful for debugging:
        // this.oauthService.events.subscribe((event) => {
        //     if (event instanceof OAuthErrorEvent) {
        //         console.error('OAuthErrorEvent Object:', event);
        //     } else {
        //         console.warn('OAuthEvent Object:', event);
        //         if (event?.type === 'token_expires') {
        //             console.log('*** Refresh token ***');
        //             // this.oauthService.refreshToken();
        //             // this.oauthService.silentRefresh();
        //             this.signOut();
        //         }

        //         if (event?.type === 'logout') {
        //             console.log('*** event logout ***');
        //             // this.signOut();
        //         }
        //     }
        // });

        // THe following cross-tab communication of fresh access tokens works usually in practice,
        // but if you need more robust handling the community has come up with ways to extend logic
        // in the library which may give you better mileage.
        //
        // See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
        //
        // Until then we'll stick to this:
        // window.addEventListener('storage', (event) => {
        //     // The `key` is `null` if the event was caused by `.clear()`
        //     if (event.key !== 'access_token' && event.key !== null) {
        //         return;
        //     }
        //     console.warn(
        //         'Noticed changes to access_token (most likely from another tab), updating isAuthenticated'
        //     );
        //     // this.isAuthenticatedSubject$.next(
        //     //     this.oauthService.hasValidAccessToken()
        //     // );
        //     this._authenticated = this.oauthService.hasValidAccessToken();
        //     if (!this.oauthService.hasValidAccessToken()) {
        //         this.navigateToLoginPage();
        //     }
        // });
        // this.oauthService.events.subscribe((_) => {
        //     // this.isAuthenticatedSubject$.next(
        //     //     this.oauthService.hasValidAccessToken()
        //     // );
        //     this._authenticated = this.oauthService.hasValidAccessToken();
        // });
        // // this.isAuthenticatedSubject$.next(
        // //     this.oauthService.hasValidAccessToken()
        // // );
        // this._authenticated = this.oauthService.hasValidAccessToken();
        // this.oauthService.events
        //     .pipe(filter((e) => ['token_received'].includes(e.type)))
        //     .subscribe((e) => this.oauthService.loadUserProfile());
        // this.oauthService.events
        //     .pipe(
        //         filter((e) =>
        //             ['session_terminated', 'session_error'].includes(e.type)
        //         )
        //     )
        //     .subscribe((e) => this.navigateToLoginPage());
    }

    configureSingleSignOn() {
        this.oidcSecurityService.checkAuth().subscribe(
            ({ isAuthenticated, accessToken }) => {
                console.log('app authenticated', isAuthenticated);
                console.log(`Current access token is '${accessToken}'`);
                if (!isAuthenticated) {
                    this.removeStore('user');
                    this.login();
                } else {
                    this._authenticated = isAuthenticated;
                    this._accessToken = accessToken;

                    this.onValidToken();
                }
            },
            (error) => {
                console.log(error);
                this.signOut();
            }
        );
    }

    onValidToken() {
        console.log('*** Valid token ***');
        // Load UserProfile to get the additional claims
        // this.oauthService.loadUserProfile().then((data) => {
        //     console.log(data);
        // });

        // Set the authenticated flag to true
        // this._authenticated = true;

        if (!this._loginUser) {
            this.fetchUserData()
                .pipe(take(1))
                .subscribe(() => {
                    this.navigateToHome();
                });
        } else {
            this.navigateToHome();
        }
    }

    navigateToHome() {
        const redirectURL =
            this._activateRoute.snapshot.queryParamMap.get('redirectURL') ||
            '/signed-in-redirect';

        // Navigate to the redirect url
        // this.router.navigateByUrl(redirectURL);
        this.router.navigateByUrl('/overview/emergency');
        // this.oauthService.setupAutomaticSilentRefresh();
    }

    private navigateToLoginPage() {
        // TODO: Remember current URL
        this.router.navigateByUrl('/sign-in');
    }

    // These normally won't be exposed from a service like this, but
    // for debugging it makes sense.
    public get accessToken() {
        // return this.oauthService.getAccessToken();
        return this._accessToken;
    }

    login(): void {
        this.oidcSecurityService.authorize();
    }

    refreshSession(): void {
        this.oidcSecurityService
            .forceRefreshSession()
            .subscribe((result) => console.log(result));
    }

    logout(): void {
        this.oidcSecurityService
            .logoff()
            .subscribe((result) => console.log(result));
    }

    logoffAndRevokeTokens(): void {
        this.oidcSecurityService
            .logoffAndRevokeTokens()
            .subscribe((result) => console.log(result));
    }

    revokeRefreshToken(): void {
        this.oidcSecurityService
            .revokeRefreshToken()
            .subscribe((result) => console.log(result));
    }

    revokeAccessToken(): void {
        this.oidcSecurityService
            .revokeAccessToken()
            .subscribe((result) => console.log(result));
    }

    setStore(key: string, data: string) {
        localStorage.setItem(key, data);
    }

    getStore(key: string): string {
        return localStorage.getItem(key);
    }

    removeStore(key: string) {
        return localStorage.removeItem(key);
    }
}
