import { LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';

export const authConfig = {
    authority: environment.ssoUrl,
    redirectUrl: window.location.origin + '/',
    postLogoutRedirectUri: window.location.origin + '/',
    clientId: 'alma-web-app',
    scope: 'openid profile email',
    responseType: 'code',
    // silentRenew: true,
    // useRefreshToken: true,
    // renewTimeBeforeTokenExpiresInSeconds: 30,
    // triggerRefreshWhenIdTokenExpired: false,
    logLevel: LogLevel.Debug,
    // secureRoutes: [
    //     'https://sso.dev.almaphil.de',
    //     'https://sso.test.almaphil.de',
    //     'https://sso.almaphil.de',
    // ],
};
