export enum INSURANCE_TYPE {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
    DIRECT_PAYER = 'DIRECT_PAYER',
}

export enum CARE_LEVEL {
    NONE = 'NONE',
    APPLYING_FOR = 'APPLYING_FOR',
    ONE = 'ONE',
    TWO = 'TWO',
    THREE = 'THREE',
    FOUR = 'FOUR',
    FIVE = 'FIVE',
}
export class AddressDTO {
    street: string;
    streetNumber: string;
    addressSuffix: string;
    city: string;
    zip: string;
    country: string = 'EP';
    region: string;
    district: string;
}

export class ResidienceDTO {
    type: string;
    access: string;
    notes: string;
    keyNumber: string;
    keySite: string;
}

export class CohabitingSituationDTO {
    withPartner = false;
    withChildren = false;
    withOtherRelatives = false;
    withDogs = false;
    withCats = false;
    withDangerousAnimals = false;
    withHarmlessAnimals = false;
}

export class HealthInsuranceDTO {
    insurer: string;
    insuranceNumber: string;
    // insuranceType: INSURANCE_TYPE = INSURANCE_TYPE.PUBLIC;
    insuranceType: INSURANCE_TYPE;
    kvNumber: string;
}

export class AnamnesisDTO {
    medications: [string];
    diseases: [string];
    allergies: [string];
    noAnamnesisInfo: true;
}

export class MedicalUserDataDTO {
    healthInsurance: HealthInsuranceDTO;
    // careLevel: CARE_LEVEL = CARE_LEVEL.NONE;
    careLevel: CARE_LEVEL;
    anamnesis: AnamnesisDTO;
    medicationLocation: string;
    hasAdvanceDirective: boolean = true;
    advanceDirectiveLocation: string;
}

export class BankAccountDTO {
    accountHolder: string;
    bankName: string;
    iban: string;
    bic: string;
    mandateReferenceNumber: string;
}

export enum GENDER {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    DIVERSE = 'DIVERSE',
    UNKNOWN = 'UNKNOWN',
}

export enum MARITAL_STATUS {
    SINGLE = 'SINGLE',
    MARRIED = 'MARRIED',
    SEPERATED = 'MARRIED',
    DIVORCED = 'DIVORCED',
    WIDOWED = 'WIDOWED',
    PARTNERED = 'PARTNERED',
    PARTNERSHIP_DISSOLVED = 'PARTNERSHIP_DISSOLVED',
    PARTNER_DIED = 'PARTNER_DIED',
    UNKNOWN = 'UNKNOWN',
}

export enum EMERGENCY_CALL_WITHOUT_VOICE {
    // CALL_AMBULANCE_AND_CONTACT = 'CALL_AMBULANCE_AND_CONTACT',
    STOP_ACTIVITIES = 'STOP_ACTIVITIES',
    CALL_CONTACT = 'CALL_CONTACT',
    CALL_AMBULANCE = 'CALL_AMBULANCE',
}

export enum MedicationType {
    GENERAL = 'GENERAL',
    SINGLE = 'SINGLE',
    CHECKBACK_GENERAL = 'CHECKBACK_GENERAL',
    CHECKBACK_SINGLE = 'CHECKBACK_SINGLE',
    CHECKBACK_PILLBOX = 'CHECKBACK_PILLBOX',
    PILLBOX = 'PILLBOX',
}

export class UserSettingCustomConfigDTO {
    id?: number;
    first_name?: string;
    last_name?: string;
    phone_number?: string;
    idType?: string;

    get fullname(): string {
        return (this.first_name || '') + '' + (this.last_name || '');
    }

    constructor(
        id?: number,
        firstName?: string,
        lastName?: string,
        phoneNumber?: string,
        idType?: string
    ) {
        this.id = id;
        this.first_name = firstName;
        this.last_name = lastName;
        this.phone_number = phoneNumber;
        this.idType = idType;
    }
}

export class UserSettingsAppDTO {
    // User settings

    title;

    // Med Reminders active/inactive
    medRemindersActive: boolean;

    // wake Word active/inactive
    wakeWordActive: boolean;
    deprecated: true;

    // Document Reminders
    documentMedReminders: boolean;

    // maximum: 6
    // minimum: 1
    // deprecated: true
    // Type of medication reminder. Deprecated: Use medReminderTypeVal instead.
    medReminderType: number;

    // Type of medication reminder
    medReminderTypeVal: MedicationType;

    // Custom configuration, for easy future extensions
    customConfig: any;

    medicationReminderType: MedicationType;

    constructor(
        title,
        medRemindersActive: boolean,
        wakeWordActive: boolean,
        documentMedReminders: boolean,
        medReminderTypeVal: MedicationType,
        customConfig: any
    ) {
        // this.title = title;
        this.medRemindersActive = medRemindersActive;
        this.wakeWordActive = wakeWordActive;
        this.documentMedReminders = documentMedReminders;
        this.medReminderTypeVal = medReminderTypeVal;
        this.customConfig = customConfig;
    }
}

export class UserDTO {
    userId: number = 0;
    // active?: boolean;
    // status?: string;
    // avatar?: string;
    login: string;
    firstName: string;
    lastName: string;
    nickName: string;
    title: string;
    firstLanguage: string;
    colloqAdd = true;
    salutation: string;
    sex: GENDER = GENDER.MALE;
    maritalStatus: MARITAL_STATUS = MARITAL_STATUS.SINGLE;
    address: AddressDTO;
    residence: ResidienceDTO;
    cohabitingSituation: CohabitingSituationDTO;
    medicalUserData: MedicalUserDataDTO;
    bankAccount: BankAccountDTO;
    birthday: string | Date;
    age = 0;
    email: string;
    phoneHome: string;
    phoneMobile: string;
    phoneEmergencyNumber: string;
    waitUntilDisponentTalks = true;
    isAgent = true;
    userEmergencyId: string;
    interests: string;
    notesMedicalPreconditions: string;
    notesSocialEnvironment: string;
    actionForEmergencyCallWithoutVoice: EMERGENCY_CALL_WITHOUT_VOICE =
        EMERGENCY_CALL_WITHOUT_VOICE.CALL_AMBULANCE;
}
